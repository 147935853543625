import SvgTextScaler from './utils/SvgTextScaler';

const App = {
  svgTextScaler: new SvgTextScaler('.text-fitting'),

  async init() {
    this.svgTextScaler.init();
  },
};

document.addEventListener('DOMContentLoaded', () => App.init());
