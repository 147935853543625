class SvgTextScaler {
  constructor(svgSelector) {
    this.svgElements = document.querySelectorAll(svgSelector);
  }

  scaleText(svgElement) {
    const textElement = svgElement.querySelector('text');

    if (textElement) {
      const bbox = textElement.getBBox();
      const bboxWidth = bbox.width.toFixed(2);
      const bboxHeight = bbox.height.toFixed(2);
      const bboxY = bbox.y.toFixed(2);

      svgElement.setAttribute('viewBox', `0 0 ${bboxWidth} ${bboxHeight}`);
      textElement.setAttribute('y', -bboxY);
    }
  }

  init() {
    document.fonts.ready.then(() => {
      this.svgElements.forEach((svgElement) => {
        this.scaleText(svgElement);
      });
    });
  }
}

export default SvgTextScaler;
